import React from "react";
import TextLoop from "react-text-loop";

const conctInfo = {
  phone: "317-660-5013",
  email: " brantlevinson@gmail.com",
};

const sliderContent = {
  name: "Brant Levinson",
  designation: "Full Stack Developer / Architect",
  description: `I design and develop large-scale, web-based systems for media, knowledge, e-commerce, and business process management.`,
  btnText: " Download Resumé",
};

const SliderRtlAnimation = () => {
  return (
    <>
      {/*  Home Banner */}
      <section id="home" className="home-banner">
        <div className="hb-top-fixed d-flex">
          <div className="hb-info">
            <a href="tel:317-660-5013">{conctInfo.phone}</a>
            <a href="mailto:mail%20to:brantlevinson@gmail.com">
              {conctInfo.email}
            </a>
          </div>
          
        </div>
        {/* End hp-top-fixed */}

        <div className="container">
          <div className="row full-screen align-items-center">
            <div className="col-lg-7">
              <div className="type-box">
                <h6 data-aos="fade-up" data-aos-duration="1200">
                  Hello, my name is
                </h6>
                <h1
                  className="font-alt"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="100"
                >
                  {sliderContent.name}
                </h1>
                <div
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="200"
                >
                  <TextLoop>
                    <p className="loop-text lead">Full Stack Developer</p>
                    <p className="loop-text lead"> Solutions Architect</p>
                    <p className="loop-text lead"> Software Architect</p>
                    <p className="loop-text lead"> DevOps Professional</p>
                  </TextLoop>
                </div>

                <p
                  className="desc"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="300"
                >
                  {sliderContent.description}
                </p>
                <div
                  className="mt-4"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="400"
                >
                  <a
                    className="px-btn px-btn-white"
                    href="img/bl-resume.pdf"
                    download
                  >
                    {sliderContent.btnText}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Container*/}
        <div
          className="hb-me"
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "img/slider/home-banner-rtl.png"
            })`,
          }}
        ></div>
      </section>

      {/* End Home Banner  */}
    </>
  );
};

export default SliderRtlAnimation;
