import React from "react";
import Skills from "../skills/Skills";

const resumeContent = [
  {
    jobPosition: `Principal Architect / Full Stack Developer`,
    jobType: `Remote`,
    jobDuration: `Feb 2022 - Present`,
    timeDuraton: `Full Time`,
    compnayName: "Wiser Solutions, Inc.",
    jobDescription: `•	Develop POCs for the consideration of technology introduction.<br>
o	Main development technologies include: 
o	NestJS (Node.js)
o	React/TypeScript
o	GraphQL
o	AWS EKS (Kubernetes)
o	NATS
•	Liaise with stakeholders and platform teams to produce architectural designs for important enterprise initiatives.
•	Provide coaching, guidance, and reviews for non-trivial feature designs.
•	Provide thought and leadership around engineering design, development, testing and delivery practices.
•	Design, develop and deliver key components used within Wiser.
•	Deliver architecture presentations to technical and non-technical audiences.
`,
  },
  {
    jobPosition: `Full Stack Developer`,
    jobType: `Remote`,
    jobDuration: `Feb 2021 - Feb 2022`,
    timeDuraton: `Consultant`,
    compnayName: "Flowable ",
    jobDescription: ` •	Developed case management system for managing the funding of research projects.
•	Worked directly with client to build system to their specifications including requirements and mockups.
•	System includes:
o	Flowable Platform
o	Sprint Boot & NodeJS Microservices
o	React/TypeScript
o	AWS EKS (Kubernetes)
`,
    delayAnimation: "200",
  },
  {
    jobPosition: `Full Stack Developer / DevOps Maintainer`,
    jobType: `Remote`,
    jobDuration: `Aug 2019 - Feb 2021`,
    timeDuraton: `Consultant`,
    compnayName: "Delaware Life Company",
    jobDescription: `•	Developed backoffice system to manage insurance policy lifecycles and commissions
•	Mainly focused on React components in a team of 15. Team included frontend/backend developers, a mockup designer, and a project manager working in an Agile/SCRUM development methodology 
•	Designed and implemented Kubernetes-based DevOps infrastructure in GKE
o	GitLab integration with build, test, review, and deployment pipeline
•	System includes:
o	Angular
o	Spring Boot Microservices
o	GKE (Kubernetes)
o	Terraform
o	GraphQL
`,
    delayAnimation: "100",
  },
  {
    jobPosition: `Full Stack Developer / DevOps Maintainer`,
    jobType: `Remote`,
    jobDuration: `Jan 2019 - Aug 2019`,
    timeDuraton: `Consultant`,
    compnayName: "DC BLOX, Inc ",
    jobDescription: `•	Lead developer building DC Blox self-service platform
•	Designed and implemented Kubernetes-based microservice infrastructure within DC Blox datacenter (on premise)
•	Developed React components
•	Created Spring Boot web services
•	Designed database schema
•	Worked through all phases of the SDLC including system analysis, design, coding, testing, debugging and documentation
•	Developed in an Agile Methodology with daily SCRUM meetings
•	Designed and implemented DevOps infrastructure - Atlassian Suite (Jira, Confluence) and Continuous Build/Integration (GitLab) in Kubernetes
•	System includes:
o	Spring Boot Microservices
o	React/TypeScript
o	PostgreSQL
o	Kubernetes
`,
    delayAnimation: "100",
  },
  {
    jobPosition: `Lead Software Engineer / Solutions Architect`,
    jobType: `Remote`,
    jobDuration: `May 2016 - Jan 2019`,
    timeDuraton: `Consultant`,
    compnayName: "MHISSION Translational Systems, LLC",
    jobDescription: `•	Analyzed previous system and gathered new business requirements
•	Prepared technical product roadmap presentation for “Knowledge, Decision, and Process Management System on the Blockchain”
•	Co-developed development plan
•	Designed and built out DevOps infrastructure - Atlassian Suite (Jira, Confluence, Git/Bitbucket, Crucible, etc.) and Continuous Build/Integration (Jenkins)
•	Setup development & production cloud infrastructure (Kubernetes)
•	Hired offshore development resources
•	Lead developer in an Agile Methodology with daily SCRUM meetings
•	Lead developer to develop product specifications
•	Lead developer in implementing functional specifications in a prototype to ensure proper clarity with the stakeholders
•	Lead developer to implement approved product
•	System includes:
o	Spring Boot Microservices
o	Angular & React Components
o	PostgreSQL
o	AWS/EKS (Kubernetes)
o	Kafka
o	Camunda
o	Solidity
o	Ethereum EVM/Hyperledger Besu
`,
    delayAnimation: "100",
  },
  {
    jobPosition: `Lead Software Engineer / Solutions Architect`,
    jobType: `Remote`,
    jobDuration: `May 2016 - Jan 2019`,
    timeDuraton: `Full Time`,
    compnayName: "O'Reilly Auto Parts",
    jobDescription: `•	Lead developer of five to build a system to handle the complex product promotion processes – Angular / Spring Boot
•	Lead developer and first line support for “O’Reilly’s Contract Management” system built on React/Redux/Node.js
•	Lead team to add a web application to accompany the java swing application to enable easier accessibility and additional functionality. 
•	Senior developer on a B2B ecommerce system that generates 43% of the revenue of the multi-billion-dollar auto retailer. System built on Spring Data REST.
•	Web front-end components are JavaScript components that communicate to a REST JSON API that uses the MyBatis/Spring infrastructure within Alfresco 4.0.
•	Manager for a Java Swing client/server (RMI) application for managing product promotions for O'Reilly retail stores.
•	Created concept, data, and object model prior to implementation.
•	Managed the project using Jira in an Agile methodology.
•	Spearheaded the procurement and company-wide adoption of “Jira Agile” within O’Reilly
•	Advised and facilitated the use of an Agile Development methodology within several groups within O'Reilly
•	Applications managed and deployed on AWS
`,
    delayAnimation: "100",
  },
  {
    jobPosition: `Senior Consultant`,
    jobType: `Remote`,
    jobDuration: `Nov 2010 - Feb 2011`,
    timeDuraton: `Consultant`,
    compnayName: "Isos Technology",
    jobDescription: `•	Gathered and defined business and functional requirements. Proposed solution. Designed, prototyped and implemented solution to enhance current product called CareRadius.
•	Lead and managed a team of five developers to build a custom rules engine and third-party integration.
•	Implemented the system with Oracle 11g, Tomcat 6.0, Spring Framework 2.5.5, iBatis 2.3.5, Java Architecture for XML Binding (JAXB), and Java Reflection API.
•	Project was done ahead of schedule and under budget with highly satisfied client
`,
    delayAnimation: "100",
  },
  {
    jobPosition: `Senior Consultant`,
    jobType: `Remote`,
    jobDuration: `Sep 2009 - Oct 2010`,
    timeDuraton: `Consultant`,
    compnayName: "Able Engineering and Component Services",
    jobDescription: `•	Gathered and defined business and functional requirements. Proposed solution. Designed, prototyped and implemented an “Employee Evaluation System” to evaluate and track how employees rate to the objectives and culture of the organization.
•	Lead and managed a team of developers working in an Agile Software Development Methodology known as Scrum utilizing Jira and GreenHopper.
•	Implemented a best of breed system on top of the existing SOA framework called OFBiz (Open For Business), which runs their ERP system. Customized the framework to enable a Web 2.0 AJAX interface by implementing a REST API that communicated to a GWT/SmartGWT and jQuery client.
•	System is in production and has been given positive accolades
`,
    delayAnimation: "100",
  },
  {
    jobPosition: `Solutions Architect / Lead Software Engineer`,
    jobType: `Scottsdale, Arizona`,
    jobDuration: `Feb 1996 - Aug 2009`,
    timeDuraton: `Full Time`,
    compnayName: "Nexxus Systems, LLC",
    jobDescription: `•	Gathered and defined business and functional requirements. Proposed solution. Designed, prototyped and implemented a “Personalized Media Delivery” system to register and distribute targeted content
•	Lead and managed a team of twelve developers working in a Scrum methodology utilizing Jira and GreenHopper
•	Implemented highly complex technical SOA specification known as TV-Anytime, which is a personalized IPTV / Internet TV Web Service based on MPEG-7 and MPEG-21 (standardized TiVo-like specification). 
•	Built a Java backend system with Web and Flex Remoting Services (BlazeDS) to Adobe Flex 3 front-end applications. 
•	Built APIs for platform and several client applications in Java, JavaScript/AJAX, C#, and VB.NET.
•	Implemented the persistence layer in Hibernate
•	Designed system to rapidly build to the specifications based on a “Model Driven” design utilizing cutting edge development tools and processes
•	Wrote patent detailing certain aspects of the system (US 20150213129 A1) 
•	This system is the first and currently the only implementation of these standards inclusive of the Bi-Directional Web Service portions (Phase II).
•	Utilized “hooks” within Liferay Portal (JSR 168) to query data via web services and cache that data on the local portal
•	Developed portlets within Liferay Portal to present the various components of the system.
•	System supports MySQL, SQL Server, Oracle, and HSQLDB
•	PAAS/Cloud version was implemented using AWS’ EC2/S3 
•	Utilized FuseESB (Based on Apache ServiceMix) as the integration hub
`,
    delayAnimation: "100",
  },
];

const educatonContent = [
  {
    passingYear: "2001-2002",
    degreeTitle: "Study: Bsc. in Information Technology",
    instituteName: "University of Phoenix",
  },
  {
    passingYear: "1995-1997",
    degreeTitle: "Study: Bsc. in Information Technology",
    instituteName: "Arizona State University",
  },
]

const Resume = () => {
  return (
    <>
      <section id="resume" className="section">
        <div className="container">
          <div className="title">
            <h3>Experience.</h3>
          </div>
          {/* End title */}
          <div className="resume-box">
            {resumeContent.map((val, i) => (
              <div
                className="resume-row"
                key={i}
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay={val.delayAnimation}
              >
                <div className="row">
                  <div className="col-md-4 col-xl-3">
                    <div className="rb-left">
                      <h6>{val.jobPosition}</h6>
                      <label>{val.jobType}</label>
                      <p>{val.jobDuration}</p>
                      <div className="rb-time">{val.timeDuraton}</div>
                    </div>
                  </div>
                  <div className="col-md-8 col-xl-9">
                    <div className="rb-right">
                      <h6>{val.compnayName}</h6>
                      <p>{val.jobDescription}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* separated */}
          <div
            className="separated"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "img/border-dark.png"
              })`,
            }}
          ></div>
          {/* End separated */}

          <div className="title">
            <h3>Education & Skills</h3>{" "}
          </div>

          <div className="row align-items-center">
            <div
              className="col-lg-4 m-15px-tb"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <ul className="aducation-box">
                {educatonContent.map((val, i) => (
                  <li key={i}>
                    <span>{val.passingYear}</span>
                    <h6>{val.degreeTitle} </h6>
                    <p>{val.instituteName}</p>{" "}
                  </li>
                ))}
              </ul>
            </div>
            {/* End .col */}

            <div
              className="col-lg-7 ml-auto m-15px-tb"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <Skills />
            </div>
            {/* End .col */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Resume;
